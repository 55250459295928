import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, } from '@mui/material';
// utils
import { bgBlur } from 'utils/cssStyles';
// components
import Iconify from 'components/iconify';
//
import AccountPopover from 'popovers/AccountPopover';
import LanguagePopover from 'popovers/LanguagePopover';

import Logo from 'components/logo';
import useResponsive from 'hooks/useResponsive';
import NotificationsPopover from '../../../popovers/notifications/NotificationsPopover';

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
    onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
    const isDesktop = useResponsive('up', 'lg');

    let currentRole = localStorage.role;
    let showNotifications = currentRole === "therapist" || currentRole === "user";
    let showHeader = !isDesktop || currentRole !== "admin";

    return showHeader && (
        <StyledRoot>
            <StyledToolbar>

                {currentRole === "admin" && (
                    <IconButton
                        onClick={onOpenNav}
                        sx={{
                            mr: 1,
                            color: 'text.primary',
                            display: { lg: 'none' },
                        }}
                    >
                        <Iconify icon="eva:menu-2-fill" />
                    </IconButton>
                )}

                <Logo />

                <Box sx={{ flexGrow: 1 }} />

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{
                        xs: 0.5,
                        sm: 1,
                    }}
                >
                    {showNotifications && (
                        <NotificationsPopover />
                    )}
                    <LanguagePopover />
                    <AccountPopover />

                </Stack>
            </StyledToolbar>
        </StyledRoot>
    );
}
