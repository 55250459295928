import { useEffect, useState } from 'react';
import { NotificationService } from '../../services/notificationService';
import server from '../../server';

export const useNotificationService = () => {
    const [notifications, setNotifications] = useState([]);
    const [service] = useState(new NotificationService(server, setNotifications));

    useEffect(() => {
        const initNotificationService = async () => {
            await service.serverUpdate();
            service.startUpdateNotifications();
        };


        initNotificationService().catch(console.error);
    }, []);

    return {
        notifications: notifications,
        readNotification: service.readNotification.bind(service),
        readAllNotifications: service.readAllNotifications.bind(service),
    };


};
