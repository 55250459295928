import React from 'react';
import { Outlet } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';

import Header from './header';
import Nav from './nav';

import server from 'server';
import Preloader from '../../components/Preloader';

// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 100; // on mobile provide space for top menu bar
const APP_BAR_DESKTOP = 110; // on desktop provide space for top menu bar

const StyledRoot = styled('div')({
    display: 'flex',
    height: '100vh',
    overflow: 'hidden'
});

const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    height: '100%',
    paddingTop: APP_BAR_MOBILE + 12,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

// ----------------------------------------------------------------------

export default class MainLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            metaLoaded: false
        };
    }

    componentDidMount() {
        this.fetchMetaInfo();
    }

    fetchMetaInfo = () => {
        server.users
            .metaInfo()
            .then((result) => {
                if (result.error === 'OK') {
                    localStorage.user = JSON.stringify(result.data.currentUser);
                    localStorage.role = result.data.currentUser.role;
                    this.setState({ metaLoaded: true });
                    this.props.context.update({
                        permissions: result.data.permissions,
                        userInfo: result.data.currentUser
                    });
                } else {
                    document.location = '/auth/login';
                }
            })
            .catch((error) => {
                document.location = '/auth/login';
            });
    };

    render() {
        let currentRole = localStorage.role;

        return (
            <StyledRoot>
                {!this.state.metaLoaded && <Preloader />}

                {this.state.metaLoaded && (
                    <>
                        <Header
                            onOpenNav={() => this.setState({ open: true })}
                        />

                        {currentRole === 'admin' && (
                            <Nav
                                openNav={this.state.open}
                                onCloseNav={() =>
                                    this.setState({ open: false })
                                }
                            />
                        )}

                        <Main>
                            <Outlet />
                        </Main>
                    </>
                )}
            </StyledRoot>
        );
    }
}
