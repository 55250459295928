import { useEffect, useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

export default function ServiceWorkerHandler() {
    const [updateAvailable, setUpdateAvailable] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState(null);

    useEffect(() => {
        serviceWorkerRegistration.register({
            onUpdate: (registration) => {
                if (registration.waiting) {
                    setWaitingWorker(registration.waiting);
                    setUpdateAvailable(true);
                }
            },
            onSuccess: () => {
                console.info('service worker on success state');
            }
        });

        const handleServiceWorkerUpdate = (event) => {
            setWaitingWorker(event.detail);
            setUpdateAvailable(true);
        };

        window.addEventListener(
            'serviceWorkerUpdateAvailable',
            handleServiceWorkerUpdate
        );

        return () => {
            window.removeEventListener(
                'serviceWorkerUpdateAvailable',
                handleServiceWorkerUpdate
            );
        };
    }, []);

    const handleUpdate = () => {
        if (waitingWorker) {
            waitingWorker.postMessage({ type: 'SKIP_WAITING' });
            setUpdateAvailable(false);
            window.location.reload();
        }
    };
    console.log('updateAvailable', updateAvailable);
    return (
        <Snackbar
            open={!!updateAvailable}
            message="New version available"
            action={<Button onClick={handleUpdate}>Update</Button>}
        />
    );
}
