import React from 'react';

// @mui
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';

import i18n from 'i18';
import RichText from '../../components/richtext';
// import server from '../../server';

export default class PreviewAnnouncementDialog extends React.Component {
    onClose = () => {};
    constructor(props) {
        super(props);
        this.state = {
            open: props.open || false,
            data: props.data || {}
        };
        this.onClose = props.onClose;
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.open !== prevState.open) {
            return {
                open: nextProps.open,
                data: nextProps.data || prevState.data
            };
        }
        return null;
    }

    render() {
        // props: dialogOpen, onClose, data: {title, description, richText}
        const { open } = this.state;
        const { title, description, richText } = this.state.data;
        return (
            <Dialog
                open={open}
                onClose={this.onClose}
                PaperProps={{
                    sx: {
                        minWidth: '400px'
                    }
                }}
            >
                <DialogTitle>{i18n.t('notifications.preview')}</DialogTitle>
                <DialogContent>
                    <div>
                        <h3>{title}</h3>
                        <p>{description}</p>
                        <RichText disableEditor={true} value={richText} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} color="primary">
                        {i18n.t('buttons.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
