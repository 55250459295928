import React from 'react';

import i18n from 'i18';

// @mui
import { TextField, Autocomplete } from '@mui/material';
import FormControl from '@mui/material/FormControl';

export default class DialogSelectField extends React.Component {
    handleChanged = (field, value) => {
        if (value === null) {
            this.props.onChange(field, '');
        } else {
            this.props.onChange(field, value.id);
        }
    };
    render() {
        // field, value, cache, onChange

        const translatedName = i18n.t(`columns.${this.props.field.label}`);
        const { id } = this.props.field;
        let value = this.props.value;

        const items = this.props.cache[this.props.field.from] || [];
        const sortedItems = items.sort((a, b) => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });
        
        if (this.props.field.showOnly) {

            const [showOnlyId, showOnlyValue] =
            this.props.field.showOnly.split('=');

            let showOnly;
            if (showOnlyId && showOnlyValue) {
                showOnly = this.props.data[showOnlyId] === showOnlyValue;
            }

            return showOnly ? (
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <Autocomplete
                        value={
                            sortedItems.find((item) => item.id === value) ||
                            null
                        }
                        onChange={(event, newValue) => {
                            this.handleChanged(id, newValue);
                        }}
                        options={sortedItems}
                        getOptionLabel={(option) => option.value}
                        isOptionEqualToValue={(option, val) => option.id === val.id}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={translatedName}
                                variant="outlined"
                            />
                        )}
                    />
                </FormControl>
            ) : null;
        }
        return (
            <FormControl fullWidth sx={{ mt: 1 }}>
                <Autocomplete
                    value={
                        sortedItems.find((item) => item.id === value) ||
                            null
                    }
                    onChange={(event, newValue) => {
                        this.handleChanged(id, newValue);
                    }}
                    options={sortedItems}
                    getOptionLabel={(option) => option.value}
                    isOptionEqualToValue={(option, val) => option.id === val.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={translatedName}
                            variant="outlined"
                        />
                    )}
                />
            </FormControl>
        );

    }
}
