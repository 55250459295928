export const swConfig = {
    onUpdate: (registration) => {
        if (registration.waiting) {
            const waitingWorker = registration.waiting;

            window.dispatchEvent(new CustomEvent('serviceWorkerUpdateAvailable', { detail: waitingWorker }));
        }
    },
    onSuccess: (registration) => {
        console.info("service worker on success state");
        console.log(registration);
    },
};
