export class NotificationService {
    localNotifications = [];

    server = null;
    callback = (_) => {};

    constructor(server, setNotificationsCallback) {
        this.server = server;
        this.callback = setNotificationsCallback;
        this.localNotifications = this.getLocalNotifications();
    }

    async fetchServerNotifications() {
        return this.server.notifications.getUnfiltered();
    }

    update(newNotificationsModel) {
        this.localNotifications = newNotificationsModel;
        localStorage.notifications = JSON.stringify(this.localNotifications);
        this.callback(newNotificationsModel);
    }

    async serverUpdate() {
        const response = await this.fetchServerNotifications();

        response.error === 'OK'
            ? this.mergeNotifications(response.data)
            : this.initFailed();
    }

    mergeNotifications(serverNotifications) {
        const newNotifications = serverNotifications.filter((n) =>
            this.localNotifications.every(
                (localNotification) => localNotification.id !== n.id
            )
        );

        this.update([
            ...newNotifications.map((n) => ({ ...n, isUnRead: true })),
            ...this.localNotifications
        ]);
    }

    getLocalNotifications() {
        return localStorage.notifications
            ? JSON.parse(localStorage.notifications)
            : [];
    }

    readNotification(id) {
        this.update(
            this.localNotifications.map((n) =>
                n.id === id ? { ...n, isUnRead: false } : n
            )
        );
    }

    readAllNotifications() {
        this.update(
            this.localNotifications.map((n) => ({
                ...n,
                isUnRead: false
            }))
        );
    }

    initFailed() {
        this.localNotifications = [];
        throw new Error('Failed to initialize notifications');
    }

    startUpdateNotifications() {
        setInterval(async () => {
            await this.serverUpdate();
        }, 60000);
    }
}
