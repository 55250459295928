export async function downloadPdfFile(id, server, handleSnackbar = (alertText) => {}) {
    try {
        const hmacResponse = await server.getHmac(id);

        if (!hmacResponse || !hmacResponse.data) {
            console.error('Invalid HMAC response from server.');
            handleSnackbar('downloadError');
            return;
        }

        const downloadResponse = await server.download(id, hmacResponse.data);

        if (downloadResponse.type !== 'application/pdf') {
            console.error('Error downloading PDF: empty response from server.');
            handleSnackbar('downloadError');
            return;
        }

        window.open(URL.createObjectURL(downloadResponse));
    } catch (error) {
        console.error('Error during file download:', error);
        handleSnackbar('downloadError');
    }
}
