import React from 'react';
import QRCode from 'react-qr-code';

import {
    Grid,
    Typography,
    Alert,
    AlertTitle,
    Stack,
    Button,
    Box
} from '@mui/material';

import i18n from 'i18';
import { AppBox, AppTests, AppList } from 'sections/dashboard';
import server from 'server';
import TestSerialInputDialog from '../dialogs/TestSerialInputDialog';
import { formatError } from 'utils/formatError';
import Preloader from '../../components/Preloader';
import SuccessAlertDialog from '../dialogs/SuccessAlertDialog';

export default class UserDashboardContent extends React.Component {
    intervalId = null;
    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            inviteCode: null,
            therapists: null,
            tests: null,
            lastTestResult: null,
            serialInputDialogOpen: false,

            successAlertText: null
        };
    }

    componentDidMount() {
        this.intervalId = setInterval(this.refreshData, 60000);
        this.refreshData();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    refreshData = () => {
        console.log('refreshData');

        server.userInviteCodes.getInviteCode().then((result) => {
            if (result.error !== 'OK') {
                this.setState({ alertText: formatError(result) });
            } else {
                this.setState({ inviteCode: result.data.inviteCode });
            }
        });

        server.tests.getAll().then((result) => {
            if (result.error !== 'OK') {
                this.setState({ alertText: formatError(result) });
            } else {
                let lastResults = result.data.filter(
                    (test) => test.status === 'result_received'
                );
                this.setState({
                    lastTestResult:
                        lastResults.length > 0 ? lastResults[0] : null,
                    tests: result.data
                });
            }
        });

        server.therapistUsers.getAll().then((result) => {
            if (result.error !== 'OK') {
                this.setState({ alertText: formatError(result) });
            } else {
                this.setState({
                    therapists: result.data.map((item) => {
                        const name =
                            item.therapist.user.firstName +
                            ' ' +
                            item.therapist.user.lastName;
                        const company = item.therapist.workCompany
                            ? ' - ' + item.therapist.workCompany
                            : '';
                        return {
                            id: item.id,
                            title: name + company
                        };
                    })
                });
            }
        });
    };

    handleSerialInputDialogOnSave(data) {
        this.refreshData();
        this.setState({
            successAlertText: i18n.t('backend.addUserTestSuccess')
        });
    }

    handleDeleteTherapist = async (id) => {
        server.therapistUsers.delete([id]).then((result) => {
            if (result.error !== 'OK') {
                this.setState({ alertText: formatError(result) });
            } else {
                this.refreshData();
            }
        });
    };

    render() {
        // const warning = 'warning';
        // const warningTitle = 'warningTitle';
        return (
            <>
                <SuccessAlertDialog
                    dialogOpen={!!this.state.successAlertText}
                    alertText={this.state.successAlertText}
                    handleClose={() =>
                        this.setState({ successAlertText: null })
                    }
                />

                <TestSerialInputDialog
                    dialogOpen={this.state.serialInputDialogOpen}
                    onSave={(data) => this.handleSerialInputDialogOnSave(data)}
                    onClose={() =>
                        this.setState({ serialInputDialogOpen: false })
                    }
                />

                <Grid container spacing={3} justify="center">
                    {/*{warning && (*/}
                    {/*    <Grid item xs={12} sm={12} md={12}>*/}
                    {/*        <Alert key="alert" severity="warning">*/}
                    {/*            <AlertTitle>*/}
                    {/*                {i18n.t(`dashboard.${warningTitle}`)}*/}
                    {/*            </AlertTitle>*/}
                    {/*            {i18n.t(`dashboard.${warning}`)}*/}
                    {/*        </Alert>*/}
                    {/*    </Grid>*/}
                    {/*)}*/}
                    {this.state.alertText && (
                        <Grid item xs={12} sm={12} md={12}>
                            <Alert key="alert" severity="error">
                                <AlertTitle>{i18n.t('text.error')}</AlertTitle>
                                {this.state.alertText}
                            </Alert>
                        </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={4}>
                        <AppBox
                            title={i18n.t('dashboard.newTest')}
                            subheader={i18n.t('dashboard.newTestSubheader')}
                        >
                            <Stack direction="column">
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        this.setState({
                                            serialInputDialogOpen: true
                                        })
                                    }
                                >
                                    {i18n.t('buttons.newTest')}
                                </Button>
                            </Stack>
                        </AppBox>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                        <AppBox
                            title={i18n.t('dashboard.lastTest')}
                            subheader={i18n.t('dashboard.lastTestSubheader')}
                        >
                            <>
                                {this.state.lastTestResult && (
                                    <Stack direction="column">
                                        <Button
                                            sx={{ my: 1 }}
                                            variant="contained"
                                            onClick={() =>
                                                this.props.navigate(
                                                    '/app/user-test-results/' +
                                                        this.state
                                                            .lastTestResult.id
                                                )
                                            }
                                        >
                                            {i18n.t('buttons.showDetails')}
                                        </Button>
                                        {/*<Button sx={{my: 1}} variant="contained" onClick={() => window.open("/api/tests/download/" + this.state.lastTestResult.id)}>
                                            {i18n.t('buttons.downloadAsPdf')}
                                        </Button>*/}
                                    </Stack>
                                )}
                                {!this.state.lastTestResult && (
                                    <Box
                                        sx={{
                                            p: 3,
                                            pb: 1,
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        dir="ltr"
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ color: '#637381' }}
                                        >
                                            {i18n.t(
                                                'dashboard.noTestResultsFound'
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                            </>
                        </AppBox>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                        <AppBox
                            title={i18n.t('dashboard.qrCode')}
                            subheader={i18n.t('dashboard.qrCodeSubheader')}
                        >
                            <>
                                {this.state.inviteCode && (
                                    <Stack direction="row">
                                        <QRCode
                                            size={96}
                                            value={this.state.inviteCode}
                                        />
                                        <Stack
                                            direction="column"
                                            sx={{ px: 5 }}
                                        >
                                            <Typography>
                                                {i18n.t('text.inviteCode')}:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: 18,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {this.state.inviteCode}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                )}
                                {!this.state.inviteCode && <Preloader />}
                            </>
                        </AppBox>
                    </Grid>

                    <Grid item xs={12} md={8} lg={8}>
                        <AppTests
                            title={i18n.t('dashboard.lastTests')}
                            subheader={i18n.t('dashboard.lastTestsSubheader')}
                            data={this.state.tests}
                        ></AppTests>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                        <AppList
                            title={i18n.t('dashboard.linkedTherapists')}
                            subheader={i18n.t(
                                'dashboard.linkedTherapistsSubheader'
                            )}
                            data={this.state.therapists}
                            onItemDelete={this.handleDeleteTherapist}
                            confirmDeleteText={i18n.t(
                                'dialogs.confirmDeleteOfLinkedTherapist'
                            )}
                        ></AppList>
                    </Grid>
                </Grid>
            </>
        );
    }
}
