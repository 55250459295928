import { Grid } from '@mui/material';
import React from "react";
import i18n from "i18";
import { AppCurrentVisits, AppWebsiteVisits, AppWidgetSummary } from "sections/dashboard";

export default class AdminDashboardContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data
        };
    }

    render() {
        const resellersPercentage = this.state.data.resellersPercentage || {};
        const testsByDateForResellers = this.state.data.testsByDateForResellers || {};
        return (
            <Grid container spacing={3}>

                <Grid item xs={12} sm={6} md={4}>
                    <AppWidgetSummary title={i18n.t('dashboard.totalUsers')} total={this.state.data.totalUsers} />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <AppWidgetSummary title={i18n.t('dashboard.totalTherapists')} total={this.state.data.totalTherapists} color="info" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <AppWidgetSummary title={i18n.t('dashboard.totalTests')} total={this.state.data.totalTests} color="warning" />
                </Grid>

                <Grid item xs={12} md={6} lg={8}>
                    <AppWebsiteVisits
                        title={i18n.t('dashboard.testsByMonth')}
                        subheader=""
                        chartLabels={this.state.data.datesLabels}
                        chartData={
                            Object.entries(testsByDateForResellers).map((field) => {
                                return {
                                    name: field[0],
                                    type: 'line',
                                    fill: 'solid',
                                    data: field[1]
                                };
                            })
                        }
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <AppCurrentVisits
                        title={i18n.t('dashboard.testsByReseller')}
                        chartData={
                            Object.entries(resellersPercentage).map((field) => {
                                return { label: field[0], value: field[1] };
                            })
                        }
                        chartColors={[
                            //theme.palette.primary.main,
                            //theme.palette.info.main,
                            //theme.palette.warning.main,
                            //theme.palette.error.main,
                        ]}
                    />
                </Grid>

            </Grid>
        );
    }

}
