//components
import GridPageAbstract from '../GridPageAbstract';
import server from '../../server';

const allControllers = [
    'users',
    'tests',
    'reference-info',
    'role-permissions',
    'therapist-users',
    'therapists',
    'reseller-tests',
    'reference-info-categories',
    'user-invite-codes'
];
// const allRoles = ['reseller', 'admin', 'therapist', 'user'];
class PermissionEditorPage extends GridPageAbstract {
    constructor(props) {
        super(props);

        this.Name = 'rolePermissions';
        this.Server = server.rolePermissions;

        this.CheckboxCell = false;
        this.AddButton = false;
        this.CanDelete = [];
        this.CanUpdate = [];

        this.Head = [
            {
                id: 'controller',
                label: 'controller',
                alignRight: false,
                cell: 'Text',
                items: allControllers,
                filterCell: 'Items',
                filterBy: 'controller',
                needTranslate: true,
                needStyle: true,
                isSingle: true,
                displayGrid: true
            },
            {
                id: 'role',
                label: 'role',
                // items: allRoles,
                // filterCell: 'Items',
                // filterBy: 'role',
                // needStyle: true,
                // isSingle: true,
                alignRight: false,
                cell: 'Text',
                needTranslate: true,
                displayGrid: true
            },
            {
                id: 'read',
                label: 'read',
                alignRight: false,
                cell: 'EditorSelect',
                displayGrid: true
            },
            {
                id: 'update',
                label: 'update',
                alignRight: false,
                cell: 'EditorSelect',
                displayGrid: true
            },
            {
                id: 'create',
                label: 'create',
                alignRight: false,
                cell: 'EditorSelect',
                displayGrid: true
            },
            {
                id: 'delete',
                label: 'delete',
                alignRight: false,
                cell: 'EditorSelect',
                displayGrid: true
            }
        ];

        this.state = {
            ...this.state,
            currentValues: {}
        };
    }

    async retrieveData() {
        await super.retrieveData();
        this.setState(
            (prevState) => ({
                currentValues: this.List.reduce((acc, cur) => {
                    acc[cur.id] = {
                        read: cur.read,
                        update: cur.update,
                        create: cur.create,
                        delete: cur.delete
                    };
                    return acc;
                }, {})
            }),
            () =>
                console.log(
                    'this.state.currentValues',
                    this.state.currentValues
                )
        );
    }

    handleChangeCell = (event, permissionId, cellId) => {
        const { value } = event.target;
        this.setState((prevState) => ({
            currentValues: {
                ...prevState.currentValues,
                [permissionId]: {
                    ...prevState.currentValues[permissionId],
                    [cellId]: value
                }
            }
        }));
    };

    handleSaveCell = async (id, role, controller) => {
        let data = this.state.currentValues[id];
        const newData = { ...data, controller, role };

        if (Object.keys(data).length) {
            await this.Server.update(id, newData);
        }
        this.retrieveData().then(() => this.updatePermissionsData());
    };
}

export default PermissionEditorPage;
