import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from 'routes';
// theme
import ThemeProvider from 'theme';
// components
import { StyledChart } from 'components/chart';
// context
import { AppDataProvider } from "./context/AppDataProvider";
import { AppDataContext } from "./context/Context";

import 'i18';
import ServiceWorkerHandler from './service-worker-handler';


export default function App() {
    let [val, setVal] = useState(true);

    globalThis.forceUpdate = () => {
        setVal(!val);
    };


    return (
        <HelmetProvider>
            <BrowserRouter>
                <ThemeProvider>
                    <StyledChart />
                    <AppDataProvider>
                        <AppDataContext.Consumer>
                            {value => <Router context={value}/>}
                        </AppDataContext.Consumer>
                        <ServiceWorkerHandler />
                    </AppDataProvider>
                </ThemeProvider>
            </BrowserRouter>
        </HelmetProvider>
    );
}
