import { useEffect } from 'react';

export const LoginWithGoogle = (props) => {

    useEffect(() => {
        window.onGoogleSuccess = (response) => {
            // hit your backend, passing up response.credential
            if (props.onSuccess) {
                props.onSuccess(response);
            }
        };

        // Inject the google provided script
        // (an importable module would be nicer here)
        const script = document.createElement('script');
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // clean up for react lifecycle
            window.onGoogleSuccess = function () {
                console.log("GOOGLE SUCCESS");
            };
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <div id="g_id_onload"
                data-client_id={"375521494809-qiq39ch3vvt3gr1v16b7p625e17s0720.apps.googleusercontent.com"}
                data-callback="onGoogleSuccess" // as defined above
                data-context="signin"
                data-ux_mode="popup"
                data-auto_prompt="false">
            </div>

            <div className="g_id_signin"
                data-type="standard"
                data-shape="rectangular"
                data-theme="filled_blue"
                data-text="signin_with"
                data-size="large"
                data-logo_alignment="left">
            </div>
        </>
    );
};
